<template>
  <div style="border-bottom: 1px solid var(--td-border-level-2-color)">
    <t-head-menu :theme="theme" height="88px" style="padding: 0 20px;box-sizing: border-box">
      <template #logo>
        <h2 style="font-size: 20px;color: var( --td-text-color-primary)">恩施州少先队实践教育地图</h2>
      </template>
      <template #operations>
        <div class="opertion">
          <div style="display: flex">
            <span class="icon_border" v-show="!search" @click="search=true">
              <icon name="search" style="color: var(--td-text-color-primary)" size="20" />
            </span>
            <t-select style="margin-right: 27px;" v-show="search" v-model="search_value" @change="pagesChange" :options="options1" filterable placeholder="搜索模块" />
          </div>
          <div @click="ChangeTheme">
            <span class="icon_border" v-if="theme==='light'">
              <img src="http://img.yltest.top/2022/05/21/628871b272ac8.png" style="width: 16px;height: 16px;">
            </span>
            <span class="icon_border" v-else>
              <img src="http://img.yltest.top/2022/05/22/6289cae095744.png" style="width: 16px;height: 16px;">
            </span>
          </div>
          <span class="icon_border">
            <t-tooltip content="没有新消息">
              <icon name="notification" style="color: var(--td-text-color-primary)" size="20" />
            </t-tooltip>
          </span>
          <t-dropdown :options="options" trigger="click" :hide-after-item-click="true" @click="logout">
            <t-avatar shape="round" style="cursor: pointer">Admin</t-avatar>
          </t-dropdown>
        </div>
      </template>
    </t-head-menu>
  </div>
</template>

<script>
import { Icon } from 'tdesign-icons-vue-next';
import router from "../../router";
import {logout} from "../../api/user";
import {MessagePlugin} from "tdesign-vue-next";
import {delToken, getToken} from "../../utils/auto";
export default {
  name: "mainHeader",
  components:{
    Icon
  },
  data(){
    return {
      popupVisible:false,
      search_value:'',
      options1:[
        {label:'展馆管理',value:'展馆管理'},
        {label:'打卡管理',value:'打卡管理'},
        {label:'活动安排',value:'活动安排'},
        {label:'用户管理',value:'用户管理'}
      ],
      options:[
        {content:'退出登录',value:'1'}
      ],
      search:false,
      theme:'light'
    }
  },
  async created() {
    let token = await getToken().role
    if(token==='admin'){
      this.options1 = [
        {label:'展馆管理',value:'展馆管理'}
      ]
    }
  },
  methods:{
    async logout(){
      let token = await getToken().token
      let data = await  logout(token)
      console.log(data)
      if(data.code===100){
        MessagePlugin.success(data.msg)
      }else {
        MessagePlugin.error(data.msg)
      }
      delToken()
      router.replace({path:'/login'})
    },
    pagesChange(e){
      switch (e){
        case '用户管理':
          router.push({path:'/main/user'})
          break
        case '展馆管理':
          router.push({path:'/main/home'})
          break
        case '打卡管理':
          router.push({path:'/main/comment'})
          break
        case '活动安排':
          router.push({path:'/main/activity'})
          break
      }
      this.search = false
    },
    ChangeTheme(){
      console.log(this.theme)
      if(this.theme === 'light'){
        // 设置暗色模式
        this.theme = 'dark'
        document.documentElement.setAttribute('theme-mode', 'dark');
      }else {
        // 重置为浅色模式
        this.theme = 'light'
        document.documentElement.removeAttribute('theme-mode');
      }
      this.$emit('c_theme',this.theme)
    }
  }
}
</script>

<style scoped>
.opertion{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.icon_border{
  margin-right: 27px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid var(--td-border-level-2-color);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
