<template>
  <div class="sider">
    <t-menu :theme="theme"  :default-value="def_meun_index" :value="muen_index" expand-mutex height="100%" width="222px">
      <t-menu-item :value="item.path" v-for="item in routers" :key="item.path" @click="go(item)">
        <template #icon>
          <t-icon :name="item.meta.icon" />
        </template>
        {{item.meta.title}}
      </t-menu-item>
    </t-menu>
  </div>
</template>

<script>
import router from "../../../router";
import {getToken} from "../../../utils/auto";
import { useRouter,useRoute } from "vue-router";
import { watch,ref } from "vue";
export default {
  name: "index",
  setup() {
    const router= useRoute();
    let muen_index = ref('')
    watch(() => router.path,(newPath, oldPath) => {
      console.log(newPath)
      muen_index.value = newPath.substr(6)
      console.log(muen_index.value)
    },{ immediate: true });

    return {muen_index}
  },
  props:['theme'],
  data(){
    return {
      routers:[],
      def_meun_index:'',
      router:null,
    }
  },
  created() {
    console.log(this.muen_index)
    console.log(useRouter().currentRoute.value.path)
    let {role} = getToken()
    let routers = router.options.routes.find(item=>{
      if(item.path==='/main'){
        return item
      }
    })
    this.routers = routers.children.filter(item=>{
      if(useRouter().currentRoute.value.path.indexOf(item.path)!==-1){
        this.def_meun_index = item.path
      }
      if(item.meta.role.indexOf(role)!==-1){
        return item
      }
    })
    console.log(this.routers)
  },
  methods:{
    go(item){
      this.$emit('to',item.path)
    }
  }
}
</script>

<style scoped>
.sider{
  height: 100%;
}
.sider>.t-default-menu .t-menu__item.t-is-active:not(.t-is-opened){
  background-color: #20C58D;
}
.sider>.t-default-menu .t-menu__item{
  height: 50px;
}
</style>
