<template>
  <div class="layout">
    <mainHeader @c_theme="c_theme"></mainHeader>
    <div class="content">
      <sider @to="rPush" :theme="theme"></sider>
      <div class="contnet_main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import mainHeader from "./components/header";
import sider from './components/sider/index'
import router from "../router";
import {ref} from "vue";
const rPush = (item)=>{
  router.push({path:item})
}
let theme = ref('')
const c_theme=(e)=>{
  theme.value=e
}
</script>

<style scoped>
.layout{
  width: 100vw;
  height: 100vh;
  background-color: var(--td-bg-color-container);
  overflow: hidden;
}
.contnet_main{
  flex: 1;
  height: 100%;
  border-left: 1px solid var(--td-border-level-2-color);
}
.content{
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
